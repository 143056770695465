import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import _ from 'lodash';

import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { refineHTMLContent } from '../utils/browser';

const Title = styled.h1`
  margin: 0 1rem 0 0;
  font-size: 2rem;
  font-weight: 500;
  color: #343434;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 2rem 0;
`;

const Field = styled.span`
  width: 120px;
  height: 30px;
  display: inline-block;
`;

const FieldValue = styled.span`
  font-weight: bold;
`;

const OriginalPrice = styled.span`
  text-decoration: line-through;
  margin-right: 10px;
`;

const NewPrice = styled.span`
  color: red;
  font-weight: bold;
`;

function PromoPage({ data }) {
  const { trangPromo: promo } = data;

  return (
    <Layout>
      <SEO title={promo.title} />

      <Header>
        <Title>{promo.title}</Title>
      </Header>
      <Field>Thời gian:</Field>
      <FieldValue>
        {`${moment(promo.validFrom).format('DD/MM/YYYY')} - ${moment(promo.validUntil).format(
          'DD/MM/YYYY'
        )}`}
      </FieldValue>
      <br />
      <Field>Áp dụng:</Field>
      <FieldValue>{promo.subscription.name}</FieldValue>
      <br />
      {!_.isNil(promo.pricing12month) && promo.pricing12month >= 0 && (
        <>
          <Field>Gói 12 tháng:</Field>
          <OriginalPrice>
            <CurrencyFormat
              value={promo.subscription.pricing12month}
              displayType="text"
              thousandSeparator=","
            />
            {` VNĐ`}
          </OriginalPrice>
          <NewPrice>
            <CurrencyFormat value={promo.pricing12month} displayType="text" thousandSeparator="," />
            {` VNĐ`}
          </NewPrice>
        </>
      )}
      {!_.isNil(promo.pricing5month) && promo.pricing5month >= 0 && (
        <>
          <Field>Gói 5 tháng:</Field>
          <OriginalPrice>
            <CurrencyFormat
              value={promo.subscription.pricing5month}
              displayType="text"
              thousandSeparator=","
            />
            {` VNĐ`}
          </OriginalPrice>
          <NewPrice>
            <CurrencyFormat value={promo.pricing5month} displayType="text" thousandSeparator="," />
            {` VNĐ`}
          </NewPrice>
        </>
      )}
      {!_.isNil(promo.pricing3month) && promo.pricing3month >= 0 && (
        <>
          <Field>Gói 3 tháng:</Field>
          <OriginalPrice>
            <CurrencyFormat
              value={promo.subscription.pricing3month}
              displayType="text"
              thousandSeparator=","
            />
            {` VNĐ`}
          </OriginalPrice>
          <NewPrice>
            <CurrencyFormat value={promo.pricing3month} displayType="text" thousandSeparator="," />
            {` VNĐ`}
          </NewPrice>
        </>
      )}
      {promo.appliedFor === 'signup' && (
        <>
          <br />
          <FieldValue>Chỉ áp dụng cho đăng ký mới!</FieldValue>
        </>
      )}
      <br />
      <br />
      <div dangerouslySetInnerHTML={{ __html: refineHTMLContent(promo.content.extended) }} />
    </Layout>
  );
}

PromoPage.propTypes = {
  data: PropTypes.shape({
    trangPromo: PropTypes.shape({
      content: PropTypes.shape({
        brief: PropTypes.string,
        extended: PropTypes.string,
      }),
      slug: PropTypes.string,
      title: PropTypes.string,
      code: PropTypes.string,
      state: PropTypes.string,
    }),
  }).isRequired,
};

export default PromoPage;

export const pageQuery = graphql`
  query($slug: String!) {
    trangPromo(slug: { eq: $slug }) {
      slug
      code
      title
      appliedFor
      state
      subscription {
        name
        code
        pricing12month
        pricing3month
        pricing5month
      }
      content {
        brief
        extended
      }
      validFrom
      validUntil
      pricing12month
      pricing3month
      pricing5month
    }
  }
`;
